import { getImage } from "gatsby-plugin-image";
import React from "react";
import { heading300, paragraphTextSmall } from "../../stitches/combinedStyles";

import { styled } from "../../stitches/stitches.config";
import { slugify } from "../../utils/slugify";
import Card from "../shared/Card/Card";

interface CardsProps {
  title: string;
  byline: string;
  data: { [key: string]: any };
}

const Cards = ({ title, byline, data }: CardsProps) => {
  return (
    <Container>
      <HeadingContainer>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
      </HeadingContainer>
      <CardsContainer>
        {data.allMarkdownRemark.edges.map((edge: any) => {
          const edgeData = edge.node.frontmatter;
          return (
            <Card
              key={edgeData.title}
              title={edgeData.title}
              date={edgeData.date}
              details={
                edgeData.description
                  ? {
                      description: edgeData.description,
                      pdfLink: edgeData.pdf.publicURL,
                    }
                  : {
                      blogLink: `/blogs/${slugify(edgeData.title)}`,
                    }
              }
              image={
                edgeData.image ? getImage(edgeData.image.childImageSharp) : null
              }
            />
          );
        })}
      </CardsContainer>
    </Container>
  );
};

export default Cards;

const Container = styled("div", {
  width: "95%",
  margin: "auto",
});

const HeadingContainer = styled("div", {
  display: "none",
  "@md": {
    display: "block",
  },
});

const Title = styled("h2", {
  ...heading300,
  margin: 0,
  "@md": {
    marginTop: 40,
  },
  "@lg": { margin: 0 },
});

const Byline = styled("p", {
  ...paragraphTextSmall,
  margin: 0,
});

const CardsContainer = styled("div", {
  margin: "auto",
  marginTop: 26,
  width: "95%",
  "@md": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  },
});
