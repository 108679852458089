import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Cards from "../components/knowledgeCentre/Cards";
import Layout from "../components/Layout";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "Case Studies",
  description:
    "Read our latest case-studies which demonstrate the versatility and value of PIP testing in various applications.",
};

function CaseStudiesPage() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/caseStudies/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              description
              pdf {
                publicURL
              }
              image {
                childImageSharp {
                  gatsbyImageData(width: 390)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <KnowledgeCentrePage seo={seo} linkPath="/case-studies">
      <Cards
        title={"Case Studies"}
        byline={
          "Read our latest case-studies which demonstrate the versatility and value of PIP testing in various applications."
        }
        data={data}
      />
    </KnowledgeCentrePage>
  );
}

export default CaseStudiesPage;
